import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/file-input/accessibility",
  "title": "FileInput - Accessibility"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "pay-attention-to",
      "style": {
        "position": "relative"
      }
    }}>{`Pay attention to`}<a parentName="h3" {...{
        "href": "#pay-attention-to",
        "aria-label": "pay attention to permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`While dragging and dropping files is not considered to be accessible, HDS will always include the default input below the drag and drop area. Clicking the drag and drop area also opens the device-specific file browser.`}</li>
      <li parentName="ul">{`For selecting files, HDS utilises the file browser native to the user's device. While all devices' file browsers do not have the best accessibility features, they are often the most familiar way of selecting files for the user.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      